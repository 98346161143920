import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/600.css';
import '@fontsource/outfit/700.css';
import '@fontsource/outfit/800.css';
import '@fontsource/outfit/900.css';

import WrapRootProvider from './src/providers/WrapRootProvider';

export const wrapRootElement = ({ element }) => (
  <WrapRootProvider>{element}</WrapRootProvider>
);
