import { createApi } from '@reduxjs/toolkit/dist/query/react';

import baseQueryWithErrorHandler from '../../helpers/baseQueryWithErrorHandler';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Auth', 'Wallet', 'Book', 'PublishStatus', 'Earnings'],
  baseQuery: baseQueryWithErrorHandler,
  endpoints: () => ({ }),
});
