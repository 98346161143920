import { ReactNode } from 'react';
import useNotifier from '../hooks/useNotifier';

const ShowSnackbar = ({ children }: { children: ReactNode; }) => {
  useNotifier();

  return children;
};

export default ShowSnackbar;
