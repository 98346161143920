import BigNumber from 'bignumber.js';
import { CurrencyCodeType } from '../types/app';
import { BookSchema } from '../types/schema';

type ListNames = 'Checkout' | 'Home' | 'Search' | 'Related Books' | 'Book Vertical Item' | 'Search List';

type PurchaseData = {
  currencyCode?: CurrencyCodeType;
  reference: string;
  voucher?: string;
  shippingFee?: number;
  discount?: number;
};

type TransformBookOptions = {
  currencyCode?: CurrencyCodeType;
  quantity?: number;
  discount?: number;
  listName?: ListNames;
}

type AuthMethod = 'Google' | 'Email';

export const toGtagItem = (
  books: Partial<BookSchema>[],
  {
    currencyCode, quantity = 1, discount, listName,
  }: TransformBookOptions,
) => books.map<Gtag.Item>((book) => {
  const pricing = book.pricings?.find((item) => item?.currencyCode === currencyCode);
  const genres = book?.genres?.filter((_, index) => index <= 4).reduce((acc, genre, index) => ({
    ...acc,
    [`item_category${index === 0 ? '' : index}`]: genre.title,
  }), {} as Record<string, string>);

  return ({
    ...genres,
    id: book.slug,
    currency: currencyCode,
    name: book.title,
    price: book.pricingType === 'free'
      ? 0
      : new BigNumber(Number(pricing?.amount || 0)).dividedBy(100).toFixed(),
    item_variant: book.type,
    quantity,
    discount,
    item_list_name: listName,
  });
});

export const trackClickEvent = (
  data: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams,
) => {
  window.dataLayer.push({ event: 'click', ...data });
};

export const trackLogin = (method: AuthMethod) => {
  window.dataLayer.push({ event: 'login', method });
};

export const trackPurchase = (item: Gtag.Item, options: PurchaseData) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    value: item.price,
    currency: options.currencyCode,
    transaction_id: options.reference,
    coupon: options.voucher,
    shipping: options.shippingFee,
    items: [item],
  });
};

export const trackSearch = (query: string) => {
  window.dataLayer.push({
    event: 'search',
    search_term: query,
  });
};

export const trackSelectContent = (type: 'book', id: string) => {
  window.dataLayer.push({ event: 'select_content', content_type: type, item_id: id });
};

export const trackSelectItem = (listName: ListNames, item: Gtag.Item) => {
  window.dataLayer.push({
    event: 'select_item',
    item_list_name: listName,
    items: [item],
  });
};

export const trackShare = (method: 'Facebook' | 'Twitter' | string, contentType: 'book', slug:string) => {
  window.dataLayer.push({
    event: 'share',
    method,
    content_type: contentType,
    item_id: slug,
  });
};

export const trackSignup = (method: AuthMethod) => {
  window.dataLayer.push({ event: 'sign_up', method });
};

export const trackViewItem = (currencyCode: string, item: Gtag.Item) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item',
    currency: currencyCode,
    value: item.price,
    items: [item],
  });
};

export const trackWishlist = (item: Gtag.Item, currencyCode?: string) => {
  window.dataLayer.push({
    event: 'add_to_wishlist',
    currency: currencyCode,
    value: item.price,
    items: [item],
  });
};

export const trackBeginCheckout = (item: Gtag.Item, currencyCode?: string, coupon?: string) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'begin_checkout',
    currency: currencyCode,
    value: item.price,
    coupon,
    items: [item],
  });
};

export const trackShippingInfo = (item: Gtag.Item, currencyCode?: string, coupon?: string) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_shipping_info',
    currency: currencyCode,
    value: item.price,
    coupon,
    items: [item],
  });
};
