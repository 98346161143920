import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '../../../src/helpers/cookies';
import useAppSelector from '../../gatsby-plugin-redux/hooks/useAppSelector';
import { RootState } from '../../gatsby-plugin-redux/store';

const selector = createSelector(
  (state: RootState) => state.auth.token,
  (token) => !!token,
);

const useIsLoggedIn = () => useAppSelector(selector) || !!getAuthToken();

export default useIsLoggedIn;
