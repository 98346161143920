import { green, red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { GatsbyLinkProps, Link as RouterLink } from 'gatsby';
import { forwardRef } from 'react';

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<unknown>, 'to'> & { href: GatsbyLinkProps<unknown>['to'] }
>(({ href, ...props }, ref) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  <RouterLink ref={ref} to={href} {...props} />
));

const theme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        component: LinkBehavior,
        underline: 'none',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    primary: {
      main: '#004e89',
    },
    secondary: {
      main: '#45AEFF',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[400],
    },
  },
  typography: {
    h1: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    h2: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    h3: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    h4: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    h5: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    h6: {
      fontFamily: ['Times New Roman', 'serif'].join(','),
    },
    fontFamily: [
      'Outfit',
    ].join(','),
  },
});

export default responsiveFontSizes(theme);
