import { createContext } from 'react';
import { CurrencyCodeType } from '../../../src/types/app';

export type CurrencyChooserProviderProps = {
  currencyCode: CurrencyCodeType;
  setCurrencyCode: (code: CurrencyCodeType) => void;
}

const CurrencyChooserContext = createContext<
  CurrencyChooserProviderProps | undefined
>(undefined);

export default CurrencyChooserContext;
