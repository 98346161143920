import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  FC, ReactNode, useMemo,
} from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from '@reach/router';
import CircularProgress from '../../../src/components/app/CircularProgress';
import useIsLoggedIn from '../hooks/useIsLoggedIn';
import Application from '../../../src/components/app/Application';
import Content from '../../../src/components/app/Content';
import { useAuthQuery } from '../../gatsby-plugin-redux/store/api/auth.api';
import SessionCheckContext, { SessionCheckContextProps } from './SessionCheckContext';

type SessionCheckProps = {
  children: ReactNode;
}

const SessionCheck: FC<SessionCheckProps> = ({ children }) => {
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();

  const { data: auth, isLoading, isSuccess } = useAuthQuery(isLoggedIn ? undefined : skipToken);

  const value = useMemo<SessionCheckContextProps>(() => ({
    auth,
    isLoggedIn: isSuccess,
  }), [auth, isSuccess]);

  const showLoading = useMemo(() => !['/404'].includes(location.pathname), [location.pathname]);

  return isLoading && showLoading
    ? (
      <Application>
        <Content>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" flexDirection="column">
            <CircularProgress sx={{ marginBottom: 3 }} />
            <Typography variant="subtitle2">We are setting you up...</Typography>
          </Box>
        </Content>
      </Application>
    )
    : (
      <SessionCheckContext.Provider value={value}>
        {children}
      </SessionCheckContext.Provider>
    );
};

export default SessionCheck;
