import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuthToken, setAuthToken } from '../../../src/helpers/cookies';

type AuthState = { token: string | null; };

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: getAuthToken() || null } as AuthState,
  reducers: {
    setToken(state, { payload }: PayloadAction<string | undefined>) {
      setAuthToken(payload);
      state.token = payload || null;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice;
