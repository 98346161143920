const getApiHostname = (apiUrl: string) => {
  const url = new URL(apiUrl);

  return `${url.protocol}//${url.host}`;
};

// Environment
export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';
export const isTest = process.env.NODE_ENV === 'test';

// InstantSearch
export const appId = process.env.GATSBY_ALGOLIA_ID!;
export const searchApiKey = process.env.GATSBY_ALGOLIA_API_KEY!;
export const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME!;
export const querySuggestionsIndexName = process.env.GATSBY_QUERY_SUGGESTIONS_INDEX_NAME!;

// Google Analytics
export const gaTrackingId = process.env.GATSBY_GTAG_ID!;

export const apiBaseUrl = process.env.GATSBY_API_BASE_URL!;
export const baseApiHostname = getApiHostname(apiBaseUrl);

if (!appId || !searchApiKey || !indexName) {
  throw new Error(
    'An environment variable is missing. Rename the \'.env.sample\' file to \'.env.local\' and change the values.',
  );
}
