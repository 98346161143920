import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  voucher?: { code?: string; price?: number; }
  total: number;
  quantity: number;
  shippingFee: number;
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    quantity: 1,
    shippingFee: 0,
    total: 0,
    voucher: {},
  } as InitialState,
  reducers: {
    addVoucher: (state, action: PayloadAction<{code:string;price:number}>) => {
      state.voucher = { ...state.voucher, ...action.payload };
    },
    addShippingFee: (state, action: PayloadAction<number>) => {
      state.shippingFee = action.payload;
    },
    addQuantity: (state, action: PayloadAction<number>) => {
      state.quantity = action.payload;
    },
    addTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
  },
});

export const {
  addQuantity, addShippingFee, addTotal, addVoucher,
} = checkoutSlice.actions;

export default checkoutSlice;
