import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import {
  FC, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { CurrencyCodeType } from '../../../src/types/app';
import useAuth from '../../gatsby-plugin-session-check/hooks/useAuth';
import CurrencyChooserContext, { CurrencyChooserProviderProps } from './CurrencyChooserContext';

const CurrencyChooserProvider: FC<{ children: ReactNode; }> = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const currency = urlSearchParams.get('currency');

  const defaultCurrencyCode = useMemo(() => currency || auth?.currencyCode || 'USD', [auth?.currencyCode, currency]);

  const [currencyCode, setCurrencyCode] = useState<CurrencyCodeType>(
    defaultCurrencyCode as CurrencyCodeType,
  );

  const handleCurrencyCode = useCallback((code: CurrencyCodeType) => {
    urlSearchParams.set('currency', code);
    setCurrencyCode(code);
    void navigate(`${location.pathname}?${urlSearchParams.toString()}`, { replace: true });
  }, [location.pathname, urlSearchParams]);

  const value = useMemo<CurrencyChooserProviderProps>(() => ({
    currencyCode,
    setCurrencyCode: handleCurrencyCode,
  }), [currencyCode, handleCurrencyCode]);

  return (
    <CurrencyChooserContext.Provider value={value}>
      {children}
    </CurrencyChooserContext.Provider>
  );
};

export default CurrencyChooserProvider;
