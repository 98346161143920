import { useEffect } from 'react';
import searchInsights from 'search-insights';
import useAuth from '../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { cookie } from '../helpers/cookies';

export type SetUserToken = (
  generatedUserToken: string,
  setToken: (userToken: string) => void
) => void

export type SearchInsightsHookOptions = {
  appId: string
  searchApiKey: string
  setUserToken: SetUserToken
}

const useSearchInsights = ({
  appId,
  searchApiKey,
  setUserToken,
}: SearchInsightsHookOptions) => {
  const auth = useAuth();

  useEffect(() => {
    searchInsights('init', {
      appId,
      apiKey: searchApiKey,
      useCookie: true,
      userToken: auth?.id || cookie.get('_ALGOLIA'),
    });

    if (typeof setUserToken === 'function') {
      searchInsights('getUserToken', null, (_, generatedUserToken) => {
        setUserToken(generatedUserToken, (userToken) => {
          searchInsights('setUserToken', userToken);
        });
      });
    }

    searchInsights('onUserTokenChange', (userToken) => {
      window.dataLayer.push({
        algoliaUserToken: userToken,
      });
    });
  }, [appId, auth?.id, searchApiKey, setUserToken]);
};

export default useSearchInsights;
