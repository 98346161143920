import { createContext } from 'react';
import { AuthSchema } from '../../../src/types/schema';

export type SessionCheckContextProps = {
  auth?: AuthSchema;
  isLoggedIn: boolean;
}

const SessionCheckContext = createContext<SessionCheckContextProps>({
  auth: {} as AuthSchema,
  isLoggedIn: false,
});

export default SessionCheckContext;
