import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import theme from '../../src/theme';

const TopLayout: FC<{ children: ReactNode; }> = (props) => (
  <>
    <Helmet>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  </>
);

export default TopLayout;
