import { SnackbarProvider } from 'notistack';
import ShowSnackbar from './components/ShowSnackbar';

const wrapWithProvider = ({ element }) => (
  <SnackbarProvider>
    <ShowSnackbar>{element}</ShowSnackbar>
  </SnackbarProvider>
);

export default wrapWithProvider;
