import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';
import { SnackbarNotificationState } from '../types/snackbar';
import { NO_CONNECTION_SNACKBAR_ID } from '../../../src/helpers/constant';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { notifications: [] as SnackbarNotificationState[] },
  reducers: {
    addSnackbar: (state, { payload }: PayloadAction<Omit<SnackbarNotificationState, 'isDismissed'>>) => {
      state.notifications.push({ ...payload, key: payload.key || uniqueId() });
    },
    closeSnackbar: (state, { payload: key }: PayloadAction<SnackbarNotificationState['key']>) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        isDismissed: (!key || notification.key === key) || undefined,
      }));
    },
    removeSnackbar: (state, { payload: key }: PayloadAction<SnackbarNotificationState['key']>) => {
      state.notifications = state.notifications.filter((notification) => notification.key !== key);
    },
  },
});

export const { addSnackbar, removeSnackbar, closeSnackbar } = snackbarSlice.actions;

export const showNoInternetSnackbarAction = () => (
  addSnackbar({
    key: NO_CONNECTION_SNACKBAR_ID,
    message: 'We are unable to perform that action. Please check your network connection and try again',
    variant: 'error',
    persist: true,
  })
);

export default snackbarSlice;
