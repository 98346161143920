import { trackLogin, trackSignup } from '../../../../src/helpers/gtag';
import { ServerResponse } from '../../../../src/types/app';
import { LoginFormData, ResetPasswordFormData, SignupFormData } from '../../../../src/types/form';
import { LoginSchema, AuthSchema } from '../../../../src/types/schema';
import { logoutAction } from '../../helpers/auth';
import { setToken } from '../auth';
import { api } from './index';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginSchema, LoginFormData>({
      query: (formData) => ({ url: 'auth/signin', body: formData, method: 'POST' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const result = await queryFulfilled;

          dispatch(setToken(result.data.token));
          trackLogin('Email');
        } catch (error) {
          //
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: 'auth/logout', method: 'POST' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(logoutAction);
        } catch (error) {
          //
        }
      },
    }),
    forgotPassword: builder.mutation<{ message: string }, { identifier: string }>({
      query: (formData) => ({ url: 'auth/forgot-password', body: formData, method: 'POST' }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordFormData>({
      query: (formData) => ({ url: 'auth/reset-password', body: formData, method: 'POST' }),
    }),
    register: builder.mutation<void, SignupFormData>({
      query: (formData) => ({ url: 'auth/signup', body: formData, method: 'POST' }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;

          trackSignup('Email');
        } catch (error) {
          //
        }
      },
    }),
    auth: builder.query<AuthSchema, void>({
      providesTags: ['Auth'],
      keepUnusedDataFor: 60 * 60,
      query: () => 'auth/me',
      transformResponse: (response: ServerResponse<AuthSchema>) => response.data,
    }),
    getAuthRedirect: builder.query<string, string>({
      query: (provider) => ({ url: 'auth/redirect', params: { provider } }),
    }),
    verifySocialAuth: builder.query<string, string>({
      query: (params) => ({ url: `auth/callback?${params}`, method: 'POST' }),
    }),
  }),
});

export const {
  useAuthQuery,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useRegisterMutation,
  useGetAuthRedirectQuery,
  useVerifySocialAuthQuery,
  useLazyGetAuthRedirectQuery,
} = authApi;
