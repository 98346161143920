import { CircularProgressProps as BaseCircularProgressProps, CircularProgress as BaseCircularProgress, Box } from '@mui/material';
import { VFC } from 'react';

type CircularProgressProps = BaseCircularProgressProps & {
  center?: boolean;
};

const CircularProgress: VFC<CircularProgressProps> = ({ center, sx, ...props }) => (
  <Box display="flex" alignItems={center ? 'center' : undefined} sx={sx} justifyContent="center">
    <BaseCircularProgress {...props} />
  </Box>
);

export default CircularProgress;
