import { Box } from '@mui/material';
import { ReactNode, FC, ElementType } from 'react';

type ContentProps = {
  children: ReactNode;
  component?: ElementType;
};

const Content: FC<ContentProps> = ({ children, component = 'main' }) => (
  <Box component={component} display="flex" maxWidth="100%" flex="1 0 auto">
    <Box flex="1 1 auto" maxWidth="100%" position="relative">
      {children}
    </Box>
  </Box>
);

export default Content;
