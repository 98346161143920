import { Helmet } from 'react-helmet-async';
import { StaticQuery, graphql } from 'gatsby';
import { FC } from 'react';

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements['meta'][];
  keywords?: string[];
  title: string;
}

const detailsQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const SEO: FC<SEOProps> = ({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
}) => (
  <StaticQuery<Queries.SEOQuery>
    query={detailsQuery}
    render={(data) => {
      const metaDescription = description || data.site?.siteMetadata?.description;
      const pageTitle = title || data.site?.siteMetadata.title;

      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={pageTitle}
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            {
              property: 'og:title',
              content: pageTitle,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:creator',
              content: data.site?.siteMetadata?.author,
            },
            {
              name: 'twitter:title',
              content: pageTitle,
            },
            {
              name: 'twitter:description',
              content: metaDescription,
            },
            ...meta,
          ]
            .concat(
              keywords.length > 0
                ? {
                  name: 'keywords',
                  content: keywords.join(', '),
                }
                : [],
            )}
        />
      );
    }}
  />
);

export default SEO;
