import { removeAuthToken } from '../../../src/helpers/cookies';
import { AppDispatch } from '../store';
import { api } from '../store/api';
import { setToken } from '../store/auth';

export const logoutAction = (dispatch: AppDispatch) => {
  dispatch(api.util.resetApiState());
  dispatch(setToken());
  removeAuthToken();
};
