import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import snackbarSlice from '../../gatsby-plugin-snackbar/store/snackbar';
import authSlice from './auth';
import { api } from './api';
import checkoutSlice from './checkout.slice';

const createStore = () => {
  const store = configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      [snackbarSlice.name]: snackbarSlice.reducer,
      [authSlice.name]: authSlice.reducer,
      [checkoutSlice.name]: checkoutSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  });

  setupListeners(store.dispatch);

  return store;
};

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore['getState']
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore['dispatch']

export default createStore;
