import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery as baseFetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { cookie } from '../../../src/helpers/cookies';
import { ErrorResponse } from '../../../src/types/app';
import { addSnackbar, showNoInternetSnackbarAction } from '../../gatsby-plugin-snackbar/store/snackbar';
import { RootState } from '../store';
import { logoutAction } from './auth';

const baseQueryWithErrorHandler: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseQuery = baseFetchBaseQuery({
    baseUrl: process.env.GATSBY_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token } = state.auth;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

  try {
    const result = await baseQuery({
      url: typeof args === 'string' ? args : args.url,
      method: typeof args === 'string' ? 'GET' : args.method,
      headers: {
        ...(typeof args === 'string' ? {} : args.headers),
        'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN'),
      },
      ...(typeof args === 'string' ? {} : args),
    }, api, extraOptions);

    if (result.error && result.error.status === 401) {
      api.dispatch(logoutAction);
    }

    if (result.error && result.error.status === 500) {
      const errorMessage = (result.error.data as ErrorResponse).message;

      api.dispatch(addSnackbar({
        message: errorMessage.toLowerCase().includes('server error')
          ? 'An unexpected error occurred. Please try again.'
          : errorMessage,
        variant: 'error',
      }));
    }

    return result;
  } catch (error) {
    api.dispatch(showNoInternetSnackbarAction());

    return {
      error: {
        data: 'We are unable to perform that action. Please check your network connection and try again',
        status: 0,
      },
    };
  }
};

export default baseQueryWithErrorHandler;
