import dayjs from 'dayjs';
import Cookies from 'js-cookie';

export const cookie = Cookies.withAttributes({
  expires: dayjs().add(6, 'months').toDate(),
});

export const getAuthToken = () => cookie.get('x-token');

export const removeAuthToken = () => cookie.remove('x-token');

export const setAuthToken = (token?: string) => {
  if (token) {
    return cookie.set('x-token', token);
  }

  return removeAuthToken();
};
