import { LocalizationProvider } from '@mui/x-date-pickers';
import { LocationProvider } from '@reach/router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import useSearchInsights, { SetUserToken } from '../hooks/useSearchInsights';
import { appId, searchApiKey } from '../helpers/config';

type WrapRootProviderProps = {
  children: ReactNode;
}

const WrapRootProvider: FC<WrapRootProviderProps> = ({ children }) => {
  const setUserToken: SetUserToken = (generatedUserToken, setToken) => {
    setToken(generatedUserToken);
  };

  useSearchInsights({
    appId,
    searchApiKey,
    setUserToken,
  });

  return (
    <HelmetProvider>
      <LocationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {children}
        </LocalizationProvider>
      </LocationProvider>
    </HelmetProvider>
  );
};

export default WrapRootProvider;
