import { useLocation } from '@reach/router';
import { navigate, PageProps } from 'gatsby';
import {
  lazy,
  ReactNode, Suspense, useEffect, useMemo,
} from 'react';
import useAuth from '../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import useIsLoggedIn from '../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';
import SEO from '../components/app/SEO';
import { PageContextWithLayout } from '../types/app';

const GeneralLayout = lazy(() => import(/* webpackChunkName: "GeneralLayout" */ './GeneralLayout'));

type LayoutProps = PageProps<null, PageContextWithLayout> & { children: ReactNode; }

const Layout = ({ children, pageContext }: LayoutProps) => {
  const auth = useAuth();
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn
      && !auth?.hasWallet
      && !['/app/onboarding', '/404'].includes(location.pathname)
    ) {
      void navigate('/app/onboarding');
    }
  }, [isLoggedIn, auth?.hasWallet, location.pathname]);

  const LayoutComponent = useMemo(() => {
    if (pageContext.layout === 'none') {
      return undefined;
    }

    return GeneralLayout;
  }, [pageContext.layout]);

  return (
    <>
      <SEO title="Connecting People to African content" />
      {LayoutComponent
        ? (
          <Suspense fallback={null}>
            <LayoutComponent>{children}</LayoutComponent>
          </Suspense>
        )
        : children }
    </>
  );
};

export default Layout;
