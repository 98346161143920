import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

type ApplicationProps = { children: ReactNode; };

const Application:FC<ApplicationProps> = ({ children }) => (
  <Box display="flex">
    <Box
      display="flex"
      flex="1 1 auto"
      minHeight="100vh"
      maxWidth="100%"
      position="relative"
      flexDirection="column"
      sx={{ backfaceVisibility: 'hidden' }}
    >
      {children}
    </Box>
  </Box>
);

export default Application;
